//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
export default {
  components: {
    CContent: () => import("@/components/CContent"),
    userTeamInfo: () => import("./userTeamInfo"),
  },
  props: {
    selRow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selUser: null,
      loading: false,
      tableData: [],
      userData: [],
      teamInfo: null,
      search: "",
      pageData: {
        pageIndex: 1,
        pageSize: 20,
        totalNum: 0,
      }, //分页
    };
  },
  watch: {
    search(val, oval) {
      if (val != oval) {
        this.getUserList();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getTeamList();
    });
  },
  filters: {
    getShape(val) {
      if (val == 1) {
        return "正常";
      } else if (val == 0) {
        return "禁用";
      } else {
        return "删除";
      }
    },
  },
  methods: {
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.pageData.pageIndex = val;
      this.getUserList();
    },
    getUserList() {
      var data = this.userData.filter(
        (data) =>
          !this.search ||
          data.Name.toLowerCase().includes(this.search.toLowerCase())
      );
      this.pageData.totalNum = data.length;
      var skip = (this.pageData.pageIndex - 1) * this.pageData.pageSize;
      this.tableData = data.slice(skip, skip + this.pageData.pageSize);
    },
    imgChange,
    /**
     * 获取团队成员列表
     */
    getTeamList() {
      this.loading = true;
      this.$http
        .post("/Teams/TeamManagementDetail.ashx", { teamId: this.selRow.Id })
        .then((resp) => {
          if (resp.res == 0) {
            this.userData = resp.data.Membersdata;
            this.teamInfo = resp.data.Teamdata;
            this.getUserList();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * 查看成员详情
     */
    handleUserDetail(val) {
      this.selUser = val;
      this.$modal.show("userTeamInfo");
    },
  },
};
